import { DB_FORMAT_DAY } from '@constants/date'
import { replaceAll } from '@/utils/string'
import { endOfDay, format, startOfDay } from 'date-fns'
import EventCronParser from 'event-cron-parser'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { Event, EventSchema, isItemType, Item, ItemType, NewEvent, NewItem, Task, TaskSchema, TemplateDB, TemplateRecur, TodoItem, TodoItemType } from 'src/types'

export * from './contextMenu'
export function generateItemId(type: ItemType, childType?: TodoItemType, selfId?: string) {
    return 'i.' + type + "." + (childType ? childType + '.' : '') + (selfId || nanoid(12));
}

export * from './task'

export const taskToTaskEventDefaults = <T extends Partial<Event>,>(task: Task, options?: T) => ({
    name: 'Work on ' + task.name,
    parentId: task.id,
    type: 'event',
    category: task.category,
    priority: 1,
    ...options,
}) as NewEvent

export const taskToAllDayTaskEvent = <T extends Partial<Event>,>(task: Task, date: Date | number | string, options?: T) => {
    const dateNum = typeof date === 'number' ? date : new Date(date).getTime()
    return taskToTaskEventDefaults(task, {
        dateStart: startOfDay(dateNum).getTime(),
        dateEnd: endOfDay(dateNum).getTime(),
        ...options,
    })
}

/**
 * no dates go to the end
 * @param a
 * @param b
 * @returns
 */
export const sortItemsByDateAscending = (a: TodoItem, b: TodoItem) => {
    if (!a.dateStart) return 1
    if (!b.dateStart) return -1
    return a.dateStart - b.dateStart
}

export function convertObjDatesToNumber(obj: Record<string, any>) {
    const keys = Object.keys(obj)
    for (let key of keys) {
        if (obj[key] instanceof Date && typeof obj[key].getTime === 'function') obj[key] = obj[key].getTime()
    }
}

export function getTypeFromId(id: string) {
    const type = id.split('.')[1]
    if (!isItemType(type)) {
        throw new Error('invalid id type: ' + type)
    }
    return type
}

export function getChildTypeFromId(id: string) {
    return id.split('.')[2] as 'event' | 'task'
}

export function getDayStringFormat(date: Date | number) {
    return format(date, DB_FORMAT_DAY)
}

export function createItemFromTemplateRecur(t: TemplateRecur, start: number, end?: number): TodoItem {
    const { type, dateStart, dateEnd, excludedDays, cron, id, ...rest } = t
    const childType = getChildTypeFromId(id)
    const schema = childType === 'event' ? EventSchema : TaskSchema
    return schema.parse({
        ...rest,
        id: id.replace('.templateRecur', '') + nanoid(6),
        recurId: id,
        dateStart: start,
        ...(end !== undefined && { dateEnd: end }), // event must have end
        type: childType,
    })
}

export function createItemFromTemplateDB(templateDB: TemplateDB, from: Date | number = Date.now()): Omit<TodoItem, 'id'> {
    let { scheduled, type, counter, nextAdd, cron, cronStart, cronEnd, daysAddInAdvance, id, ...item } = templateDB
    const childType = getChildTypeFromId(id)
    let date: number | undefined = undefined
    let duration = 0
    if (cron) {
        const cronParser = new EventCronParser(cron, cronStart, cronEnd)
        duration = cronParser.parsedCron.duration
        date = cronParser.next(from)?.getTime() || undefined
    }
    if (childType === 'task' && duration) throw new Error('task cannot have duration')

    return cloneDeep({
        ...item,
        name: replaceAll(item.name, '#c', counter.toString()), // TODO: empty string name allowed for templateDB?
        dateStart: date,
        ...(date && duration && { dateEnd: date + duration }),
        type: childType,
    })
}

export function createNewItemPlaceholder(item: NewItem) {
    const id = item.id || `n.${item.type}.${'childType' in item ? item.childType + '.' : ''}${nanoid()}`
    return {
        ...item,
        createdAt: item.createdAt || Date.now(),
        updatedAt: item.createdAt || Date.now(),
        id, // n for new, changes to i after loading
        category: item.category || '/',
    } as Item
}

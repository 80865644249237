
import { ContextMenuUnit } from "@/components/common/ContextMenu";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { Item, TemplateRecur } from "src/types";
import { getTypeFromId } from "@/utils/item";
import { format, startOfDay } from "date-fns";
import { DB_FORMAT_DAY } from "@constants/date";


export const createItemContextMenu = (item: Item, handleEdit: () => void, remove: (id: string) => void, inForm = false): ContextMenuUnit[] => {
    if (!item.id) return [] // loading or unfinished add item

    const type = getTypeFromId(item.id)
    let menu: ContextMenuUnit[] = [
        {
            label: 'Edit',
            rightSlot: <Pencil1Icon />,
            onSelect: handleEdit, // TODO should open up a form, or switch tabs if inForm=true
        },
        {
            label: 'Delete',
            rightSlot: <TrashIcon />,
            onSelect: () => remove(item.id),
        },
    ]

    if (type === 'task') {
        // menu = [
        //     ...menu,
        //     ...createTaskContextMenu(item as unknown as Task, mutate)
        // ]
    }
    // else if (type === 'event') { }
    else if (type === 'event' || ('recurId' in item && item.recurId)) {
        menu = [
            ...menu,
            // ...createTemplateRecurContextMenu(item as unknown as TemplateRecur, mutate)
        ]
    }
    else if (type === 'templateDB') { }
    return menu
}


// CURRENTLY DISABLED HIDE TASK OPTION (wa)
// const hideOptions = { '1 hour': { ms: MS_PER_HOUR }, '1 day': { ms: MS_PER_DAY, confirmMsg: 'Sure?' }, '3 days': { ms: MS_PER_DAY * 3, confirmMsg: '3 days is pretty long...' }, '1 week': { ms: MS_PER_WEEK, confirmMsg: 'Are you SURE about 1 WHOLE WEEK' } }
// const createTaskContextMenu = (item: Task, mutate: () => void): ContextMenuUnit[] => {
//     return [{
//         label: 'Hide',
//         content: Object.keys(hideOptions).map(desc => {
//             // @ts-expect-error
//             const option: any = hideOptions[desc]

//             return {
//                 label: `For ${desc}`,
//                 onSelect: async () => {
//                     if (option.confirmMsg && !window.confirm(option.confirmMsg)) return
//                     await fetch('/api/item', {
//                         method: 'PATCH',
//                         headers: { 'Content-type': 'application/json', },
//                         body: JSON.stringify({ id: item.id, hideUntil: Date.now() + option.ms })
//                     })
//                     mutate()
//                 }
//             }
//         })
//     },]
// }
const createTemplateRecurContextMenu = (item: TemplateRecur, mutate: () => void): ContextMenuUnit[] => [
    {
        label: 'Exclude',
        rightSlot: <TrashIcon />,
        onSelect: async () => {
            await fetch('/api/main/item', {
                method: 'PATCH',
                headers: { 'Content-type': 'application/json', },
                body: JSON.stringify({
                    id: item.id, add: new Set([
                        format(startOfDay(item.dateStart), DB_FORMAT_DAY)
                    ])
                }),
                cache: 'no-store'
            })
            mutate()
        },
    },
]
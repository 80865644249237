import EventCronParser from 'event-cron-parser';
import { z } from 'zod'

export const cronSchema = z.custom<string>((val: any) => {
    if (typeof val !== 'string') return false
    try {
        new EventCronParser(val).validate()
        return true;
    } catch (e) {
        console.error('invalid cron', e, val)
        return false
    }
});

export default z;
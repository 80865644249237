import { isEqual, startOfDay } from 'date-fns'
import { MS_PER_DAY, MS_PER_MINUTE } from '@planda/utils'
import z, { cronSchema } from 'src/lib/zod'
import { LinkSchema } from './input'
import { LocationSchema } from './input'
import { SubtaskSchema } from './mini'
import { MAX_ITEM_DURATION } from '@constants/item'
import { LOTS_OF_MS } from '@constants/date'
// if intersection = never: https://stackoverflow.com/questions/71183840/why-type-intersections-results-in-never-type

export type MakeNewItem<Type> = Omit<Type, 'createdAt' | 'updatedAt' | 'id'>

/**
 * TASKS vs EVENTS
 * tasks:
 * - completed
 * - no dateEnd
 * - dateStart optional
 * - subtasks optional
 * events:
 * - dateStart and dateEnd required
 */
/**
 * if type of parentId is another event, maybe this is for generic events, such as meals + sleep (or would this be a generic task with no dates?)
 * i feel like generic events are best made of a very very custom template? or a template where changes to individuals will stick
 */

/** TODO
 * TemplateDB vs TemplateRecur
 * db:
 * recur:
 */

export const todoItemTypeSchema = z.literal('task').or(z.literal('event'))
export type TodoItemType = 'event' | 'task'
export type ItemType = TodoItemType | 'templateDB' | 'templateRecur'

export type DatedItem = Event | DatedTask | TemplateRecur

export type Item = Task | Event | TemplateDB | TemplateRecur

// item tags: restful, workblock, exam, lecture
export const ItemTagEnum = z.enum(['workblock', 'exam', 'lecture'])
/**
 * Bases
 */
const baseItemObject = {
    createdAt: z.number(),
    name: z.string().min(1, { message: 'Required' }), // only db-events don't have to have names in special cases (ex taskEvents)
    updatedAt: z.number(),
    id: z.string(),
    category: z.string(), // empty is empty string '/'
    priority: z.number(), // 0 1 2
    notes: z.string().optional(),
    links: z.array(LinkSchema).optional(),
    googleCalendarId: z.string().optional(),
    googleCalendarCalendarId: z.string().optional(),
    categoryName: z.string().optional(),
    isRestful: z.boolean().optional(),
    tags: ItemTagEnum.array().optional(),
}
const ItemBaseSchema = z.object(baseItemObject)

const baseTodoObject = {
    groupId: z.string().optional(),
    parentId: z.string().optional(),
    recurId: z.string().optional(), // for items from templateRecur // ex. parentId for taskEvents + recurring events, use id to access parents from a dictionary
    gradeInPercentage: z.number().optional(), // what grade you received
    gradingWeightInPercentage: z.number().optional(), /// how much it is worth
    dateIsTentative: z.boolean().optional(),
    completed: z.number().nonnegative().optional(), // 0 means not completed, else means date (timestamp) completed
} as const

const BaseTodoSchema = z.object({
    ...baseItemObject,
    ...baseTodoObject,
})

const BaseTemplateSchema = ItemBaseSchema.extend({
    childType: todoItemTypeSchema,
    cron: cronSchema.optional(),
})

/**
 * Events
 */
export const EventSchema = BaseTodoSchema.extend({
    type: z.literal('event'),
    dateStart: z.number(),
    dateEnd: z.number(),
    location: LocationSchema.optional(),
    isWorkBlock: z.boolean().optional(),
    workBlockItems: z.array(z.string()).optional(),
})
// want strict check before adding to database and after reading form db
export const StrictEventSchema = EventSchema.strict().superRefine(superRefineEventDates)
export type Event = z.infer<typeof EventSchema>

/**
 * Tasks
 */

export const TaskSchema = BaseTodoSchema.extend({
    dateStart: z.number().optional(),
    completed: z.number().nonnegative(),
    subtasks: z.array(SubtaskSchema).optional(),
    type: z.literal('task'),
    hideUntil: z.number().optional(), // DEPRECATED, may remove soon
    templateDBId: z.string().optional(),
    dateHandedOut: z.number().optional(), // temporary patch, until dateStart -> dateEnd for tasks
    estimatedTimeInMinutes: z.number().nonnegative().optional(),
    difficulty: z.number().int().min(0).max(5).optional(),
    bulletHeight: z.number().optional(),
})

export type Task = z.infer<typeof TaskSchema>
export const StrictTaskSchema = TaskSchema.strict()
export interface DatedTask extends Task {
    dateStart: number
}

/** Todo */
export const TodoItemMergeSchema = TaskSchema.partial()
    .omit({ type: true })
    .merge(EventSchema.partial().omit({ type: true }))
    .extend({
        type: todoItemTypeSchema.optional(),
    })
export type TodoItemMerge = Partial<Omit<Event, 'type'>> & Partial<Omit<Task, 'type'>> & { type: TodoItemType }
export type TodoItem = Task | Event
export type DatedTodoItem = Event | DatedTask

/** TemplateDB */
export const BaseTemplateDBSchema = BaseTemplateSchema.extend({
    type: z.literal('templateDB'),
    scheduled: z.literal(0).or(z.literal(1)),
    counter: z.number(),
    nextAdd: z.number().optional(),
    cron: cronSchema.optional(), // no cron means dateStart === null
    cronStart: z
        .number()
        .nullish()
        .transform((x) => x ?? undefined), //
    cronEnd: z.number().optional(),
    daysAddInAdvance: z.number().nonnegative().optional(),
})

export const TemplateDBTaskSchema = TaskSchema.omit({ dateStart: true })
    .partial()
    .merge(BaseTemplateDBSchema)
    .extend({ childType: z.literal('task') })
export const TemplateDBEventSchema = EventSchema.omit({ dateStart: true, dateEnd: true })
    .partial()
    .merge(BaseTemplateDBSchema)
    .extend({ childType: z.literal('event') })
export const TemplateDBSchema = TemplateDBTaskSchema.or(TemplateDBEventSchema)
export const TemplateDBMergeSchema = TemplateDBTaskSchema.merge(TemplateDBEventSchema).extend({ childType: todoItemTypeSchema })
export const StrictTemplateDBSchema = TemplateDBTaskSchema.strict().refine(refineTemplateDB).or(TemplateDBEventSchema.strict().refine(refineTemplateDB))
export type TemplateDBTask = z.infer<typeof TemplateDBTaskSchema>
export type TemplateDBEvent = z.infer<typeof TemplateDBEventSchema>
export type TemplateDB = z.infer<typeof TemplateDBSchema>
export type TemplateDBMerge = z.infer<typeof TemplateDBMergeSchema>

const regex = /^\d{4}-\d{2}-\d{2}$/

/** TemplateRecur */
export const BaseTemplateRecurSchema = BaseTemplateSchema.extend({
    // recurring events fall in this category
    type: z.literal('templateRecur'),
    dateStart: z.number(),
    dateEnd: z.number(), // pick a specific date to represent infinity
    cron: cronSchema,
    excludedDays: z
        .union([
            z.set(
                z
                    .string()
                    .regex(regex)
                    .refine((dateStr) => !!Date.parse(dateStr))
            ),
            z.array(
                z
                    .string()
                    .regex(regex)
                    .refine((dateStr) => !!Date.parse(dateStr))
            ),
        ])
        .optional()
        .transform((x) => x && [...x]),
    // year-monthIndex-day string format, parsed by split('-') and parseInt, then spread into new Date(). IMPORTANT: don't directly put in new Date() or it will be utc
})
export const TemplateRecurEventSchema = EventSchema.merge(BaseTemplateRecurSchema).extend({
    childType: z.literal('event'),
    dateEnd: z
        .number()
        .positive()
        .optional()
        .transform((arg) => (arg && arg >= LOTS_OF_MS ? undefined : arg)),
})
export const TemplateRecurSchema = TemplateRecurEventSchema
export const StrictTemplateRecurSchema = TemplateRecurSchema.strict().superRefine(superRefineDates)
export type TemplateRecurEvent = z.infer<typeof TemplateRecurEventSchema>
export type TemplateRecur = z.infer<typeof TemplateRecurSchema>

export function isEvent(item: Item): item is Event {
    return (item as Event).type === 'event'
}

export function isTask(item: { id?: string; type?: string; [x: string]: any }): item is Task {
    return (item as Task).type === 'task'
}

export function isTemplateDB(item: Item): item is TemplateDB {
    return (item as TemplateDB).type === 'templateDB'
}

export function isTemplateRecur(item: Item): item is TemplateRecur {
    return (item as TemplateRecur).type === 'templateRecur'
}

export function isTemplateRecurEvent(item: Item): item is TemplateRecurEvent {
    return (item as TemplateRecurEvent).type === 'templateRecur' && (item as TemplateRecurEvent).childType === 'event'
}

export function isTodoItemType(type: string): type is TodoItemType {
    return (type as TodoItemType) === 'task' || (type as TodoItemType) === 'event'
}

export function isItemType(type: string): type is ItemType {
    return (type as ItemType) === 'task' || (type as ItemType) === 'event' || (type as ItemType) === 'templateDB' || (type as ItemType) === 'templateRecur'
}

export function isItem(item: Item): item is TodoItem {
    return isTask(item as TodoItem) || isEvent(item as TodoItem)
}

export function isAllDayEvent(item: Item) {
    if (!isEvent(item)) return false
    const duration = item.dateEnd - item.dateStart
    if (duration < MS_PER_DAY - MS_PER_MINUTE * 5 || duration > MS_PER_DAY) return false
    return isEqual(item.dateStart, startOfDay(item.dateStart))
}

export function isDatedItem(item: Item | NewItem): item is DatedItem {
    // @ts-expect-error
    return item.dateStart !== undefined
}

// TODO: add field option. ex. add fields for professor + canvas link

export const itemSchemaDict = {
    event: EventSchema,
    task: TaskSchema,
    templateRecur: TemplateRecurSchema,
    templateDB: TemplateDBSchema,
}

export function superRefineEventDates(data: { dateStart: number; dateEnd: number }, ctx: any) {
    superRefineDates(data, ctx)
    if (data.dateEnd <= data.dateStart)
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'End date must be after start',
        })
    if (data.dateEnd - data.dateStart > MAX_ITEM_DURATION) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Duration too long',
        })
    }
}

export function superRefineDates(data: { dateStart: number; dateEnd?: number }, ctx: any) {
    if (data.dateEnd && data.dateEnd <= data.dateStart)
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'End date must be after start',
        })
}

export function refineTemplateDB({ scheduled, nextAdd, daysAddInAdvance, cron }: any): boolean {
    if (scheduled) return nextAdd !== undefined && daysAddInAdvance !== undefined && cron !== undefined
    return true
}

export function preFilterArrayByField(val: any, field: string) {
    const arr = val?.filter((x: any) => x[field])
    if (!arr || arr.length === 0) return undefined
    return arr
}
/**
 * PUT item for main
 */
export const PARTIAL_IN_PUT_ITEM = { category: true, createdAt: true, updatedAt: true, id: true, priority: true } as const
export const PARTIAL_IN_PUT_TASK = { ...PARTIAL_IN_PUT_ITEM, completed: true } as const
const EXTEND_IN_PUT = {
    links: z.preprocess((val: any) => preFilterArrayByField(val, 'url'), z.array(LinkSchema).optional()),
    subtasks: z.preprocess((val: any) => preFilterArrayByField(val, 'name'), z.array(SubtaskSchema).optional()),
} as const

// subtasks: z.preprocess((val: any) => preFilterArrayByField(val, 'name'), z.array(SubtaskSchema).optional()),
export const PutEventSchema = EventSchema.partial(PARTIAL_IN_PUT_ITEM).extend(EXTEND_IN_PUT)
export const PutTaskSchema = TaskSchema.partial(PARTIAL_IN_PUT_TASK).extend(EXTEND_IN_PUT)
export const PutTemplateRecurSchema = TemplateRecurSchema.partial(PARTIAL_IN_PUT_ITEM).extend(EXTEND_IN_PUT)
export const PutTemplateDBTaskSchema = TemplateDBTaskSchema.partial(PARTIAL_IN_PUT_TASK).extend(EXTEND_IN_PUT)
export const PutTemplateDBEventSchema = TemplateDBEventSchema.partial(PARTIAL_IN_PUT_ITEM).extend(EXTEND_IN_PUT)
export const PutTemplateDBSchema = PutTemplateDBTaskSchema.or(PutTemplateDBEventSchema)
export const PutTemplateDBMergeSchema = TemplateDBMergeSchema.partial(PARTIAL_IN_PUT_TASK).extend(EXTEND_IN_PUT)
export type NewTemplateDBTask = z.infer<typeof PutTemplateDBTaskSchema>
export type NewTemplateRecur = z.infer<typeof PutTemplateRecurSchema>
export type NewTask = z.infer<typeof PutTaskSchema>
export type NewEvent = z.infer<typeof PutEventSchema>
export type NewItem = NewTemplateDBTask | NewTemplateRecur | NewTask | NewEvent
export const PutItemSchema = PutEventSchema.or(PutTaskSchema).or(PutTemplateRecurSchema).or(PutTemplateDBSchema)

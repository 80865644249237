import { UpdateItemParams } from "dynamodb-helpers";
import { assignValueFromPath } from "./array";
import { omitBy } from "lodash";

export const attributeNameToPath = (attributeName: string) => {
    const arr: (string | number)[] = [];
    const components = attributeName.split(".");
    for (let c of components) {
        const regex = /\[\d+\]/g;
        const split = c.split(regex);
        const matches = c.match(regex)?.map((x) => parseInt(x.slice(1, -1)));
        for (let i = 0; i < split.length; i++) {
            arr.push(split[i]);
            if (matches && i < matches.length) {
                arr.push(matches[i]);
            }
        }
    }
    return arr;
};

/**
 *
 * @param x
 * @param updates
 * @returns
 */
export const dynamodbUpdateItemOptimistic = <Item extends {}>(x: Item, updates: UpdateItemParams) => {
    const { set, setIfNotExists, remove, listAppend, add } = updates
    // TODO: what about not updates.set?
    // handle updates.set
    let item: Record<string, any> = x
    // Object.assign(x, set);

    if (set) {
        // [`subtasks[${index}].completed`]
        for (let [key, val] of Object.entries(set)) {
            // TODO: check if assignValueFromPath works or test this function with codium
            item = assignValueFromPath(item, attributeNameToPath(key), val)
        }
    }
    if (setIfNotExists) {
        for (let [key, val] of Object.entries(setIfNotExists)) {
            item = assignValueFromPath(item, attributeNameToPath(key), val, {
                setIfNotExists: true,
            })
        }
    }

    // handle updates.remove
    if (remove) {
        item = omitBy(item, (v, k) => v === undefined || remove.includes(k)) as Item

        for (const key of remove) {
            if (key.endsWith(']')) {
                const [listKey, ...indexStr] = key.slice(0, -1).split('[')
                if (!item[listKey]) continue
                if (indexStr.length !== 1) throw new Error('indexStr wrong length updateItemFromCache')
                const index = parseInt(indexStr[0])
                if (Number.isNaN(index)) {
                    // TODO: check this works for subtasks removeSubtask
                    throw new Error('TODO: check that remove works updateItemFromCache')
                    // continue;
                }
                item[listKey].splice(index, 1)
            }
        }
    }
    if (listAppend) {
        Object.entries(listAppend).forEach(([key, val]) => {
            if (!item[key]) item[key] = []
            item[key] = item[key].concat(val)
        })
    }
    if (add) {
        Object.entries(add).forEach(([key, val]) => {
            if (typeof val === 'number') {
                if (!item[key]) item[key] = 0
                item[key] += val
                return
            }
            if (Array.isArray(item[key])) {
                item[key] = item[key].concat([...val])
            }
            // item[key] is a Set
            const iterator: Set<any> = item[key] || new Set()
            ;[...val].forEach((x) => iterator.add(x))
            item[key] = iterator
        })
    }

    // handle updates.listAppend
    return item
}

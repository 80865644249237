export * from './auth'
export * from './util'
export * from './item'
export * from './api'
export * from './categories'
export * from './db'
export * from './input'
export * from './user'
export * from './mini'

export type PartialK<T, K extends PropertyKey = PropertyKey> = Partial<Pick<T, Extract<keyof T, K>>> & Omit<T, K> extends infer O ? { [P in keyof O]: O[P] } : never

export type SearchParams = { [key: string]: string | string[] | undefined }

export type Modify<T, R> = Omit<T, keyof R> & R;
